const ENV = process.env.NODE_ENV || "development";

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const PAYMENT_PROGRESS = process.env.REACT_APP_PAYMENT_PROGRESS;

export {
    ENV,
    STRIPE_PUBLIC_KEY,
    PAYMENT_PROGRESS
};
