import { useState } from "react"
import { loadStripe } from '@stripe/stripe-js';
import { ENV, STRIPE_PUBLIC_KEY, PAYMENT_PROGRESS } from "../config"
import Logo from "../assets/logo.svg"

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY!);

const debug = ENV === "development"
let base = "https://api.justdjango.com"
if (debug) {
    base = "http://127.0.0.1:8000"
}

const api = {
    createCheckoutSession: `${base}/api/create-product-checkout-session/`
}

function PurchaseButton({ loading, setLoading, title }: { loading: boolean, setLoading: (val: boolean) => void, title: string }) {

    async function handleCheckoutSession() {
        const stripe = await stripePromise;
        setLoading(true);
        fetch(api.createCheckoutSession, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product_name: "Tutorial Hub"
            })
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (session) {
                return stripe!.redirectToCheckout({ sessionId: session.sessionId });
            })
            .then(function (result) {
                if (result.error) {
                    alert(result.error.message);
                }
            })
            .catch(function (error) {
                console.error('Error:', error);
                setLoading(false);
            });
    }

    return (
        <button
            onClick={handleCheckoutSession}
            disabled={loading}
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
            {title}
            {loading && (
                <span className="loader ml-3">
                    <svg className="animate-spin -ml-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10"
                            stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
            )}
        </button>
    )
}

export function Home() {
    const [loading, setLoading] = useState<boolean>(false)
    return (

        <div className="min-h-screen bg-white">
            <div className="py-3">
                <main>
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">

                        <div>

                            <div className="relative overflow-hidden">
                                <div className="relative pb-12 lg:pb-20">
                                    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-20">
                                        <div className="text-center">
                                            <a href="https://justdjango.com">
                                                <img src={Logo}
                                                    className="h-12 w-12 sm:h-16 sm:w-16 mx-auto mb-6 sm:mb-10" alt="JustDjango" />
                                            </a>
                                            <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                                JustDjango
                                                <br />
                                                <span className="text-red-600">Tutorial Hub</span>
                                            </h1>
                                            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                                Access a curated database of over 250 of the best free Django tutorials on the
                                                internet
                                            </p>

                                            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                                                <div className="rounded-sm shadow">
                                                    <PurchaseButton loading={loading} setLoading={setLoading} title="Improve my Django knowledge" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative pb-12 lg:pb-20">
                                    <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
                                        <iframe className="rounded shadow w-full"
                                            title="Airtable for Tutorial Hub"
                                            src="https://airtable.com/embed/shry3OQVJDvuBheH2?backgroundColor=green&viewControls=on"
                                            frameBorder="0" height="533"></iframe>
                                    </div>
                                </div>

                                <div className="max-w-xl mx-auto relative py-16 border border-gray-200 shadow-md px-4 sm:px-6 lg:px-8">
                                    <div className="relative">
                                        <div className="text-lg max-w-prose mx-auto mb-6">
                                            <h2 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                                Stuck in tutorial hell?
                                            </h2>
                                        </div>
                                        <div className="text-gray-600 text-lg mx-auto">
                                            <p className="mt-3">
                                                Today there is a plethora of tutorials on the internet. New blog posts and video
                                                tutorials
                                                are created every day.
                                            </p>
                                            <p className="mt-3">
                                                Don't get me wrong - this is a good thing! More people are engaging in the
                                                Django community
                                                than ever before.
                                            </p>
                                            <p className="mt-3">
                                                Sometimes the challenge is not following the tutorial, but figuring out who the
                                                best people
                                                are to follow. This abundance of learning material can make it difficult to find
                                                the right
                                                tutorials. You spend way too much time finding the tutorials in the first place.
                                            </p>
                                            <p className="mt-3">As Django improves, tutorials get older, and outdated.</p>
                                            <p className="mt-3 text-indigo-600">That's where JustDjango Tutorial Hub comes in.</p>
                                            <p className="mt-3">Tutorial Hub makes it easier than ever to find the most up-to-date, and
                                                easy-to-follow Django
                                                tutorials.
                                            </p>
                                            <p className="mt-3">
                                                Stop searching. <b>Focus on learning</b>.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="py-16 relative sm:px-6 px-4">
                                    <h3 className="text-2xl text-center tracking-tight leading-10 text-gray-900 sm:text-4xl sm:leading-none md:text-2xl">
                                        Lifetime access - $47 one time
                                    </h3>
                                    <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                                        <div className="rounded-sm shadow">
                                            <PurchaseButton loading={loading} setLoading={setLoading} title="Improve my Django knowledge" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                                <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">

                                    <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                                        <div className="relative">
                                            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                                                Tutorials for getting started, forms, docker, API's, integrations with React,
                                                and lots more!
                                            </h4>
                                            <ul className="mt-10">
                                                <li>
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div className="ml-4">
                                                            <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                Elevate your skills in multiple areas of Django
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div className="ml-4">
                                                            <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                Focus on one area of Django and double-down
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div className="ml-4">
                                                            <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                Up-to-date, easy-to-follow and high quality tutorials
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mt-10 -mx-4 relative lg:mt-0">
                                            <img className="relative mx-auto rounded-sm shadow" width="490"
                                                src="https://assets.justdjango.com/static/images/university-first.png"
                                                alt="Tutorial Hub Tag Filters" />
                                        </div>
                                    </div>

                                    <div className="relative mt-12 sm:mt-16 lg:mt-24">
                                        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                                            <div className="lg:col-start-2">
                                                <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                                                    A resource for every level of developer - from beginners to fullstack devs.
                                                </h4>

                                                <ul className="mt-10">
                                                    <li>
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <svg className="flex-shrink-0 h-6 w-6 text-green-500"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M5 13l4 4L19 7"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="ml-4">
                                                                <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                    Save tens of hours of finding tutorials
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="mt-3">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <svg className="flex-shrink-0 h-6 w-6 text-green-500"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M5 13l4 4L19 7"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="ml-4">
                                                                <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                    The best foundation for beginners getting started
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="mt-3">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <svg className="flex-shrink-0 h-6 w-6 text-green-500"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M5 13l4 4L19 7"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="ml-4">
                                                                <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                    A trove of knowledge for teams and companies
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                                                <img className="relative mx-auto" width="490"
                                                    src="https://assets.justdjango.com/static/images/university-second.png"
                                                    alt="Tutorial Hub Difficulty Filters" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="relative mt-12 sm:mt-16 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                                        <div className="relative">
                                            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                                                Quick sort by tutorial type, estimated learning time or difficulty
                                            </h4>
                                            <ul className="mt-10">
                                                <li>
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div className="ml-4">
                                                            <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                Find tips and optimisations
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div className="ml-4">
                                                            <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                Learn with your preferred medium - video, written and more.
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"></path>
                                                            </svg>
                                                        </div>
                                                        <div className="ml-4">
                                                            <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                                The most comprehensive Django resource online
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mt-10 -mx-4 relative lg:mt-0">
                                            <img className="relative mx-auto" width="490"
                                                src="https://assets.justdjango.com/static/images/university-third.png"
                                                alt="Tutorial Hub Difficulty Filters" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="py-12 relative pb-6 lg:pb-12 sm:px-6 px-4">
                                <h3 className="text-2xl text-center tracking-tight leading-10 text-gray-900 sm:text-4xl sm:leading-none md:text-2xl">
                                    Lifetime access - $47 one time
                                </h3>
                                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                                    <div className="rounded-sm shadow">
                                        <PurchaseButton loading={loading} setLoading={setLoading} title="Improve my Django knowledge" />
                                    </div>
                                </div>
                            </div>

                            <div className="py-12 bg-white pb-12 lg:pb-20">
                                <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="lg:text-center">
                                        <h3 className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-10">
                                            6 Reasons to get the JustDjango Tutorial Hub right now
                                        </h3>
                                    </div>

                                    <div className="mt-10">
                                        <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                                            <li>
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-navy-500 text-white">
                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                                                            Instant access to over 250 tutorials</h4>
                                                        <p className="mt-2 text-base leading-6 text-gray-700">
                                                            You could spend weeks or months finding random tutorials and hope they're good, or you could access 250+ in seconds. Time is money.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mt-10 md:mt-0">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-navy-500 text-white">
                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                                                            Made by a Django developer and teacher</h4>
                                                        <p className="mt-2 text-base leading-6 text-gray-700">
                                                            I've spent the past three years teaching web development with Django. I know who's who, and whether tutorials are up-to-date, easy-to-follow and generally good for learning.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mt-10 md:mt-0">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-navy-500 text-white">
                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                                                            Made specifically for learning Django
                                                        </h4>
                                                        <p className="mt-2 text-base leading-6 text-gray-700">
                                                            Every tutorial is hand-picked and meant to be used for learning Django. There are also more complex tutorials that teach integration with React, Vue, etc.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mt-10 md:mt-0">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-navy-500 text-white">
                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                                                            More knowledge, more power to you
                                                        </h4>
                                                        <p className="mt-2 text-base leading-6 text-gray-700">
                                                            Django is an extremely vast framework. I'm confident there is something in here for you. If not, get a full refund.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mt-10 md:mt-0">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-navy-500 text-white">
                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                                                            Free updates on all new added tutorials</h4>
                                                        <p className="mt-2 text-base leading-6 text-gray-700">
                                                            Stay updated with new tutorials as I discover them and add to the asset. Yours free, forever.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mt-10 md:mt-0">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-navy-500 text-white">

                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                                                            100% Money-back lifetime guarantee</h4>
                                                        <p className="mt-2 text-base leading-6 text-gray-500">
                                                            Not happy for any reason? Just let me know and I'll refund your purchase in full, any time.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="relative pb-12 lg:pb-20 sm:px-6 px-4">

                                <h3 className="text-3xl text-center tracking-tight leading-10 text-{{ price_tier.colour }}-500 sm:text-4xl sm:leading-none md:text-2xl">
                                    Lifetime Access - $47 one time
                                </h3>
                                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                                    <ul>
                                        <li>
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                </div>
                                                <div className="ml-4">
                                                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                        250+ tutorials and growing!
                                                    </h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                </div>
                                                <div className="ml-4">
                                                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                        Lifetime updates
                                                    </h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                </div>
                                                <div className="ml-4">
                                                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                                                        Filtered by categories and tags
                                                    </h5>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mt-5 max-w-md mx-auto md:mt-8 pb-12 lg:pb-16">
                                    <div className="w-full text-center">
                                        Left at this price: <span
                                            className="text-blue-500 font-semibold">{75 - PAYMENT_PROGRESS}</span><span
                                                className="text-gray-500">/75</span>
                                    </div>
                                    <div className="shadow w-full bg-gray-100 rounded-md">
                                        <div className="bg-blue-500 text-xs leading-none py-1 text-center text-white h-4"
                                            style={{ width: `${Math.floor(100 * PAYMENT_PROGRESS / 75)}%` }}></div>
                                    </div>
                                    <div className="rounded-sm shadow mt-4">
                                        <PurchaseButton loading={loading} setLoading={setLoading} title="Grab the Lifetime Deal" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </main>
            </div>

            <footer className="bg-white">
                <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
                    <p className="mt-8 text-center text-base leading-6 text-gray-400">
                        Made by <a className="text-blue-500 hover:text-blue-600" href="https://twitter.com/justdjangocode"
                            rel="noreferrer noopener" target="_blank">@justdjangocode</a>.
                    </p>
                </div>
            </footer>
        </div>
    )
}